import React, { useEffect, useState } from "react";

import axios from "../../axios-base";
const BADGE_LEVELS = [
  "🌟 Elite Champion – Most wins overall.",
  "🎖 Streak Master – Won at least 3 weeks in a row.",
  "🏆 Gold – Won at least 60% of the total weeks.",
  "🥈 Silver – Won at least 40% of the total weeks.",
  "🥉 Bronze – Won at least 20% of the total weeks.",
  "🎖️ Participant – Competed but didn’t win.",
  "🎯 Attempt – Participated at least once (but never won).",
];

const UserBadges = ({ userId, token }) => {
  const [badges, setBadges] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchBadges = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/opanda/tests/badges`,
          {
            user_id: userId
          }, // Ensure this is a boolean if needed
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
          }
        );
        setBadges(response.data.badges);
        console.log("Badges:", response.data.badges);
      } catch (error) {
        console.error("Error fetching badges:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBadges();
  }, [userId]);

  const levenshteinDistance = (a, b) => {
    const dp = Array(a.length + 1)
      .fill(null)
      .map(() => Array(b.length + 1).fill(null));

    for (let i = 0; i <= a.length; i++) dp[i][0] = i;
    for (let j = 0; j <= b.length; j++) dp[0][j] = j;

    for (let i = 1; i <= a.length; i++) {
      for (let j = 1; j <= b.length; j++) {
        const cost = a[i - 1] === b[j - 1] ? 0 : 1;
        dp[i][j] = Math.min(dp[i - 1][j] + 1, dp[i][j - 1] + 1, dp[i - 1][j - 1] + cost);
      }
    }
    return dp[a.length][b.length];
  };

  const findMostSimilar = (query, words) => {
    let closestMatch = "";
    let minDistance = Infinity;

    for (const word of words) {
      const distance = levenshteinDistance(query.toLowerCase(), word.toLowerCase());
      if (distance < minDistance) {
        minDistance = distance;
        closestMatch = word;
      }
    }
    return closestMatch;
  };

  if (loading) return <p className="text-center text-gray-500">Loading badges...</p>;
  if (badges.length === 0) return <p className="text-center text-gray-500"></p>;

  return (
    <div className="flex flex-wrap gap-2 justify-center">
      {badges.map((badge) => (
        <div key={badge.id} className="relative group rounded-lg text-center p-4">
          <div style={{ fontSize: "30px" }}>
            <span style={{ fontFamily: "emoji" }}>
              {findMostSimilar(badge.badge_level, BADGE_LEVELS).split(" ")[0]}
            </span>
          </div>

          {/* Tooltip for Competition Details */}
          <div className="absolute left-1/2 bg-white transform -translate-x-1/2 top-full mt-2 w-48 text-xs p-2 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity shadow-lg">
            <p className="font-bold">🏆 Competition Details</p>
            <p>
              <span style={{ fontFamily: "emoji" }}>
                {findMostSimilar(badge.badge_level, BADGE_LEVELS)}
              </span>
            </p>
            <p>Start: {new Date(badge.competition_start).toLocaleDateString()}</p>
            <p>End: {new Date(badge.competition_end).toLocaleDateString()}</p>
          </div>
        </div>
      ))}
    </div>


  );
};

export default UserBadges;
