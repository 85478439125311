import { CardSubText } from "../../../elements/text";
import { AnchorButton } from "../../../elements/button";

const NotificationBar = (props) => {
  return (
    <div className="bg-red w-full sticky">
      <div className="mx-auto flex justify-center py-1">
        <CardSubText
          name="You are currently in Preview mode. To gain full access, "
          alignment="center"
          mobileAlignment="left"
          color="white"
        />
        <AnchorButton
          to="register"
          name="Register today"
          color="white"
          alignment="center"
        />&nbsp;
        
      </div>
    </div>
  );
};

export const NotificationBarCompetition = (props) => {
  return (
    (false)?<div className="bg-red w-full sticky">
      <div className="mx-auto flex justify-center py-1 animate-bounce">
        <CardSubText
          name="DEADLINE: 19th-Jan 2025! Compete and Win prizes by performing the best and the most tests weekly"
          alignment="center"
          mobileAlignment="left"
          color="white"
        /> &nbsp;
        <AnchorButton
          to="register"
          name="Register today"
          color="white"
          alignment="center"
        />&nbsp;
        
      </div>
    </div>:""
  )
}
export default NotificationBar;
