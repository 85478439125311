import axios from 'axios';

export const LOAD_LEADERBOARD_USERS_REQUEST = 'LOAD_LEADERBOARD_USERS_REQUEST';
export const LOAD_LEADERBOARD_USERS_SUCCESS = 'LOAD_LEADERBOARD_USERS_SUCCESS';
export const LOAD_LEADERBOARD_USERS_FAILURE = 'LOAD_LEADERBOARD_USERS_FAILURE';

export function loadLeaderboardUsersRequest() {
  return {
    type: LOAD_LEADERBOARD_USERS_REQUEST,
  };
}

export function loadLeaderboardUsersSuccess(data) {
  return {
    type: LOAD_LEADERBOARD_USERS_SUCCESS,
    payload: data,
  };
}

export function loadLeaderboardUsersFailure(error) {
  return {
    type: LOAD_LEADERBOARD_USERS_FAILURE,
    payload: error,
  };
}

export const loadLeaderboardUsers = (data) => {
  return async (dispatch) => {
    dispatch(loadLeaderboardUsersRequest());
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/opanda/tests/tests_assessments_stats_leaderboard`, data, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response.data);
        dispatch(
          loadLeaderboardUsersSuccess(response.data.results)
        );
      })
      .catch((err) => {
        dispatch(loadLeaderboardUsersFailure(err.message));
      });
  };
};


export const SET_SHOW_LEADERBOARD = 'SET_SHOW_LEADERBOARD';

export const setShowLeaderBoard = (status) => {
  return {
    type: SET_SHOW_LEADERBOARD,
    status,
  };
};
