import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineLooksOne, MdOutlineLooksTwo } from 'react-icons/md';
import { loadLeaderboardUsers } from '../../../store/actions/student.leaderboard';
import Spinner from '../spinner';
import { Button } from '../button';
import { CardBody, CardTitle } from '../text';

// Sample JSON data
// const leaderboardData = [
//   {
//     names: "Christian m",
//     email: "####deleted###rutacris21@gmail.com",
//     subjects_count: 6,
//     combined_marks: 39.54
//   },
//   {
//     names: "llfsfsf kgdkjggjk",
//     email: "student0001@opanda.xyz",
//     subjects_count: 3,
//     combined_marks: 15.84
//   }
// ];


const Leaderboard = () => {

  const dispatch = useDispatch();

  const userAth = useSelector((state) => state.auth);
  const leaderboardData = useSelector((state) => state.Leaderboard.data[0]);
  const leaderboardloader = useSelector((state) => state.Leaderboard.loading);

  const [weekRange, setWeekRange] = useState({ startDate: '', endDate: '' });
  const [currentWeekOffset, setCurrentWeekOffset] = useState(0); // Offset to calculate weeks

  // Calculate the start and end dates for a given week offset
  const calculateWeekRange = (weekOffset) => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const daysSinceMonday = (dayOfWeek === 0 ? 7 : dayOfWeek) - 1; // Account for Sunday as 0
  
    const currentMonday = new Date(today);
    currentMonday.setDate(today.getDate() - daysSinceMonday + weekOffset * 7);
  
    const endOfWeek = new Date(currentMonday);
    endOfWeek.setDate(currentMonday.getDate() + 6);
  
    const startDate = currentMonday.toISOString().split('T')[0];
    const endDate = endOfWeek.toISOString().split('T')[0];
  
    // Check if the start date is before 2024-12-16
    if (new Date(startDate) < new Date("2024-12-16")) {
      return {
        startDate: "2024-12-16",
        endDate: new Date("2024-12-22").toISOString().split('T')[0], // A single day as the range
      };
    }

    // Check if the start date is before 2024-12-16
    if (new Date(endDate) > new Date("2025-01-19")) {
      return {
        startDate: "2025-01-13",
        endDate: new Date("2025-01-19").toISOString().split('T')[0], // A single day as the range
      };
    }
  
    return {
      startDate,
      endDate,
    };
  };
  
  // Update the week range when the offset changes
  useEffect(() => {
    const range = calculateWeekRange(currentWeekOffset);
    setWeekRange(range);

    // Dispatch the action for the current week
    dispatch(
      loadLeaderboardUsers({
        token: userAth.token,
        academic_level: userAth.academic_level,
        startDate: range.startDate,
        endDate: range.endDate,
      })
    );
  }, [currentWeekOffset, dispatch, userAth.token, userAth.academic_level])


  return (
    (false) ? <div className="bg-white shadow-md rounded-lg">
      <CardTitle className="text-xl font-semibold mb-2" name="Weekly Leaderboard stats(Prizes for top 20):" />

      {/* Week Navigation Buttons */}
      <div className="flex space-x-4 mb-4">
        <Button
          name="Prev"
          outline="true"
          color="blue"
          clicked={() => setCurrentWeekOffset((prev) => prev - 1)}
        />

        <Button
          name="This week"
          outline="true"
          color="blue"
          clicked={() => setCurrentWeekOffset(0)}
        />
        <Button
          name="Next"
          outline="true"
          color="blue"
          clicked={() => setCurrentWeekOffset((prev) => prev + 1)}
        />
      </div>
      {/* Display Current Week */}
      <div className="mb-4">
        <CardBody className="text-lg font-semibold" name={`Selected week: ${weekRange.startDate} <=> ${weekRange.endDate}`} />

      </div>
      <div style={{ maxHeight: "300px", overflowY: "auto" }} className="overflow-x-auto overflow-y-auto max-h-[40vh]">
        {leaderboardData?.length > 0 ? (
          leaderboardData.map((user, index) => (
            <div key={index} className={'p-3 flex items-center justify-between border-t cursor-pointer hover:bg-gray-200'}>
              <div className="flex items-center">
                <CardTitle name={`${index + 1})`} />
                <div className="ml-2 flex flex-col">
                  <div className="leading-snug text-sm text-gray-900 font-bold">{user.names}</div>
                  <div className="leading-snug text-xs text-gray-600">{user.email}</div>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <div className="leading-snug text-xs text-gray-600">Marks: {Math.round(user.combined_marks)}/100</div>
                <div className="leading-snug text-sm text-gray-900 font-bold">Tests done: {Math.round(user.subjects_count)}</div>
              </div>
            </div>
          ))
        ) : (
          (leaderboardloader) ? (<Spinner size="16" color="blue" />) : (<div className="p-3 text-center text-gray-600">No users found in the leaderboard.</div>)
        )}
      </div>
    </div> : ""
  );
};

export default Leaderboard;